const routes = [
	{
		path: '/admin',
		name: 'Admin',
		component: () => import('@/views/Admin'),
		meta: {
			requiresAuth: true,
			userType: 0
		},
		children: [
			{
				path: 'home',
				name: 'Home',
				component: () => import('@/views/admin/Home'),
				meta: {
					requiresAuth: true,
					userType: 0
				}
			},
			{
				path: 'orders',
				name: 'Orders',
				component: () => import('@/views/admin/Orders'),
				meta: {
					requiresAuth: true,
					userType: 0
				}
			},
			{
				path: '*',
				name: 'Error',
				component: () => import('@/views/Error'),
				meta: {
					requiresAuth: true,
					userType: 0
				}
			},
			{
				path: '*',
				component: () => import('@/views/admin/Home')
			}
		]
	}
];

export default routes;
